/* custom-sw.js */
import { clientsClaim, setCacheNameDetails } from 'workbox-core';
import { ExpirationPlugin } from 'workbox-expiration';
import { cleanupOutdatedCaches, precacheAndRoute } from 'workbox-precaching';
import { registerRoute, setCatchHandler } from 'workbox-routing';
import { CacheFirst, NetworkFirst, StaleWhileRevalidate } from 'workbox-strategies';

setCacheNameDetails({
  prefix: 'padhai-app',
  suffix: 'v1',
});

cleanupOutdatedCaches();

/* eslint-disable no-restricted-globals */
// This gets replaced with actual list during build
precacheAndRoute(self.__WB_MANIFEST);
registerRoute(
  ({ request }) => request.destination === 'script' || request.destination === 'style',
  new StaleWhileRevalidate({
    cacheName: 'padhai-static-resources',
  })
);

registerRoute(
  ({ request }) => request.destination === 'image',
  new CacheFirst({
    cacheName: 'padhai-image-cache',
    plugins: [
      new ExpirationPlugin({
        maxEntries: 50, // Adjust based on your needs
        maxAgeSeconds: 30 * 24 * 60 * 60, // 30 days
        purgeOnQuotaError: true,
      }),
    ],
  })
);

registerRoute(
  ({ url }) => url.pathname.startsWith('/api'),
  new NetworkFirst({
    cacheName: 'padhai-api-cache',
    networkTimeoutSeconds: 5, // Adjust threshold based on your app’s needs
  })
);

setCatchHandler(({ event }) => {
  if (event.request.destination === 'document') {
    return caches.match('/offline.html');
  }
  return Response.error();
});

// precacheAndRoute([
//   { url: '/', revision: 'some-revision-hash' },
//   { url: '/offline.html', revision: 'some-revision-hash' },
// ]);

self.addEventListener('install', (event) => {
  self.skipWaiting();
  clientsClaim();
});
